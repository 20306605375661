body{
    font-size: 12px;
  }
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .text-right{
    text-align: right !important;
  }
  
  .card-lg{
    max-height: 900px !important;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .mb-15{
    margin-bottom: 15px;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .jVeafb{
    font-size:12px !important;
  }
  
  .mt-15{
    margin-top: 15px !important;
  }
  
  .mb-30{
    margin-bottom: 30px !important;
  }
  
  .form-control{
    font-size:12px !important;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 0;
  }
  
  .inputTableCell {
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 0;
    outline: none;
  }
  
  .inputTableCell:focus,  .form-control:focus {
    outline: none !important;
    box-shadow:none;
  }
  
  .color-white{
    color:#fff !important;
  }
  
  .cards_pagos .btn {
    font-size: 12px;
    padding: 2px 5px;
    line-height: 1.4;
  }
  
  .input-custom-lg {
    font-size: 25px !important;
    font-weight: 600;
    padding: 3px 10px;
    text-align: right !important;
  }

.btn.disabled, .btn:disabled, fieldset:disabled .btn{
    border-color: #e9ecef !important;
    background-color: #e9ecef !important;
    color: #878383 !important;
}
  
  .contenedorImpresion{
    display:none ;
  }  
  
  input.input_number_table_pagos::-webkit-outer-spin-button,
  input.input_number_table_pagos::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  
  input.input_number_table_pagos[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }
  
  .table2 th, .table2 td {
      padding: 5px;
      font-size: 11px;
  }
  
  button[data-testid="expander-button-undefined"] svg{
      margin-left: 0px !important;
      margin-right: 0px !important;
  }
  
  button[data-testid="expander-button-undefined"]{
      width: auto !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
  }
  
  .sc-hKMtZM{
      min-width: 25px !important;
      flex: 0 0 25px !important;
  }

  #print{
    /* visibility: hidden; */
}


.ticket{
  text-align: center;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  page-break-before: always;
  color: #000 !important;
  width:100% !important;
}    

.w-100{
  width: 100%;
  height: auto;
}

.ticket .table td{  
  /* font-family: sans-serif !important; */
  font-family: monospace !important;
  font-size: 12px;
  text-align: left !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  letter-spacing: -0.5px;
  border:0px !important;
  color: #000 !important;
}

.ticket .cab td{
  font-size: 14px !important;
  line-height: 1.2 !important;
  text-align:center !important;
}

.ticket .data td{
  font-size: 12px !important;
  font-weight: normal !important;
  line-height: 1.2 !important;
}


  